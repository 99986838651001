export const authActionTypes = {
    CHECK_USER_SESSION: "CHECK_USER_SESSION",

    METAMASK_SIGN_IN_START: "METAMASK_SIGN_IN_START",
    WALLETCONNECT_SIGN_IN_START: "WALLETCONNECT_SIGN_IN_START",
    NIFTYWALLET_SIGN_IN_START: "NIFTYWALLET_SIGN_IN_START",
    COINBASE_SIGN_IN_START: 'COINBASE_SIGN_IN_START',

    SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
    SIGN_IN_FAILURE: "SIGN_IN_FAILURE",

    SIGN_OUT_START: 'SIGN_OUT_START',
    SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
    SIGN_OUT_FAILURE: "SIGN_OUT_FAILURE"
}
