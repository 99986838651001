export const moviesActionTypes = {
    FETCH_COMEDY_MOVIES_REQUEST: 'FETCH_COMEDY_MOVIES_REQUEST',
    FETCH_COMEDY_MOVIES_SUCCESS: 'FETCH_COMEDY_MOVIES_SUCCESS',
    FETCH_COMEDY_MOVIES_FAILURE: 'FETCH_COMEDY_MOVIES_FAILURE',
    LOAD_MORE_COMEDY_MOVIES_SUCCESS: 'LOAD_MORE_COMEDY_MOVIES_SUCCESS',

    FETCH_BANNER_MOVIES_REQUEST: 'FETCH_BANNER_MOVIES_REQUEST',
    FETCH_BANNER_MOVIES_SUCCESS: 'FETCH_BANNER_MOVIES_SUCCESS',
    FETCH_BANNER_MOVIES_FAILURE: 'FETCH_BANNER_MOVIES_FAILURE',
    LOAD_MORE_BANNER_MOVIES_SUCCESS: 'LOAD_MORE_BANNER_MOVIES_SUCCESS',

    FETCH_CARTOON_BANNER_MOVIES_REQUEST: 'FETCH_CARTOON_BANNER_MOVIES_REQUEST',
    FETCH_CARTOON_BANNER_MOVIES_SUCCESS: 'FETCH_CARTOON_BANNER_MOVIES_SUCCESS',
    FETCH_CARTOON_BANNER_MOVIES_FAILURE: 'FETCH_CARTOON_BANNER_MOVIES_FAILURE',
    LOAD_CARTOON_MORE_BANNER_MOVIES_SUCCESS: 'LOAD_CARTOON_MORE_BANNER_MOVIES_SUCCESS',

    FETCH_CLASSICS_BANNER_MOVIES_REQUEST: 'FETCH_CLASSICS_BANNER_MOVIES_REQUEST',
    FETCH_CLASSICS_BANNER_MOVIES_SUCCESS: 'FETCH_CLASSICS_BANNER_MOVIES_SUCCESS',
    FETCH_CLASSICS_BANNER_MOVIES_FAILURE: 'FETCH_CLASSICS_BANNER_MOVIES_FAILURE',
    LOAD_CLASSICS_MORE_BANNER_MOVIES_SUCCESS: 'LOAD_CLASSICS_MORE_BANNER_MOVIES_SUCCESS',

    FETCH_FEATURED_MOVIES_REQUEST: 'FETCH_FEATURED_MOVIES_REQUEST',
    FETCH_FEATURED_MOVIES_SUCCESS: 'FETCH_FEATURED_MOVIES_SUCCESS',
    FETCH_FEATURED_MOVIES_FAILURE: 'FETCH_FEATURED_MOVIES_FAILURE',
    LOAD_MORE_FEATURED_MOVIES_SUCCESS: 'LOAD_MORE_FEATURED_MOVIES_SUCCESS',

    FETCH_HORROR_MOVIES_REQUEST: 'FETCH_HORROR_MOVIES_REQUEST',
    FETCH_HORROR_MOVIES_SUCCESS: 'FETCH_HORROR_MOVIES_SUCCESS',
    FETCH_HORROR_MOVIES_FAILURE: 'FETCH_HORROR_MOVIES_FAILURE',
    LOAD_MORE_HORROR_MOVIES_SUCCESS: 'LOAD_MORE_HORROR_MOVIES_SUCCESS',

    FETCH_GENERIC_CARTOONS_REQUEST: 'FETCH_GENERIC_CARTOONS_REQUEST',
    FETCH_GENERIC_CARTOONS_SUCCESS: 'FETCH_GENERIC_CARTOONS_SUCCESS',
    FETCH_GENERIC_CARTOONS_FAILURE: 'FETCH_GENERIC_CARTOONS_FAILURE',
    LOAD_MORE_GENERIC_CARTOONS_SUCCESS: 'LOAD_MORE_GENERIC_CARTOONS_SUCCESS',

    FETCH_GENERIC_MOVIES_REQUEST: 'FETCH_GENERIC_MOVIES_REQUEST',
    FETCH_GENERIC_MOVIES_SUCCESS: 'FETCH_GENERIC_MOVIES_SUCCESS',
    FETCH_GENERIC_MOVIES_FAILURE: 'FETCH_GENERIC_MOVIES_FAILURE',
    LOAD_MORE_GENERIC_MOVIES_SUCCESS: 'LOAD_MORE_GENERIC_MOVIES_SUCCESS',

    FETCH_CHAPLIN_MOVIES_REQUEST: 'FETCH_CHAPLIN_MOVIES_REQUEST',
    FETCH_CHAPLIN_MOVIES_SUCCESS: 'FETCH_CHAPLIN_MOVIES_SUCCESS',
    FETCH_CHAPLIN_MOVIES_FAILURE: 'FETCH_CHAPLIN_MOVIES_FAILURE',
    LOAD_MORE_CHAPLIN_MOVIES_SUCCESS: 'LOAD_MORE_CHAPLIN_MOVIES_SUCCESS',

    FETCH_THREE_STOOGES_MOVIES_REQUEST: 'FETCH_THREE_STOOGES_MOVIES_REQUEST',
    FETCH_THREE_STOOGES_MOVIES_SUCCESS: 'FETCH_THREE_STOOGES_MOVIES_SUCCESS',
    FETCH_THREE_STOOGES_MOVIES_FAILURE: 'FETCH_THREE_STOOGES_MOVIES_FAILURE',
    LOAD_MORE_THREE_STOOGES_MOVIES_SUCCESS: 'LOAD_MORE_THREE_STOOGES_MOVIES_SUCCESS',

    FETCH_ROMANCE_MOVIES_REQUEST: 'FETCH_ROMANCE_MOVIES_REQUEST',
    FETCH_ROMANCE_MOVIES_SUCCESS: 'FETCH_ROMANCE_MOVIES_SUCCESS',
    FETCH_ROMANCE_MOVIES_FAILURE: 'FETCH_ROMANCE_MOVIES_FAILURE',
    LOAD_MORE_ROMANCE_STOOGES_MOVIES_SUCCESS: 'LOAD_MORE_ROMANCE_MOVIES_SUCCESS',

    FETCH_POPEYE_MOVIES_REQUEST: 'FETCH_POPEYE_MOVIES_REQUEST',
    FETCH_POPEYE_MOVIES_SUCCESS: 'FETCH_POPEYE_MOVIES_SUCCESS',
    FETCH_POPEYE_MOVIES_FAILURE: 'FETCH_POPEYE_MOVIES_FAILURE',
    LOAD_MORE_POPEYE_STOOGES_MOVIES_SUCCESS: 'LOAD_MORE_POPEYE_MOVIES_SUCCESS',

    FETCH_FUNNY_MOVIES_REQUEST: 'FETCH_FUNNY_MOVIES_REQUEST',
    FETCH_FUNNY_MOVIES_SUCCESS: 'FETCH_FUNNY_MOVIES_SUCCESS',
    FETCH_FUNNY_MOVIES_FAILURE: 'FETCH_FUNNY_MOVIES_FAILURE',
    LOAD_MORE_FUNNY_MOVIES_SUCCESS: 'LOAD_MORE_FUNNY_MOVIES_SUCCESS',

    FETCH_DRAMA_MOVIES_REQUEST: 'FETCH_DRAMA_MOVIES_REQUEST',
    FETCH_DRAMA_MOVIES_SUCCESS: 'FETCH_DRAMA_MOVIES_SUCCESS',
    FETCH_DRAMA_MOVIES_FAILURE: 'FETCH_DRAMA_MOVIES_FAILURE',
    LOAD_MORE_DRAMA_MOVIES_SUCCESS: 'LOAD_MORE_DRAMA_MOVIES_SUCCESS',

    FETCH_OTHERS_MOVIES_REQUEST: 'FETCH_OTHERS_MOVIES_REQUEST',
    FETCH_OTHERS_MOVIES_SUCCESS: 'FETCH_OTHERS_MOVIES_SUCCESS',
    FETCH_OTHERS_MOVIES_FAILURE: 'FETCH_OTHERS_MOVIES_FAILURE',
    LOAD_MORE_OTHERS_MOVIES_SUCCESS: 'LOAD_MORE_OTHERS_MOVIES_SUCCESS',

    FETCH_LOONEY_MOVIES_REQUEST: 'FETCH_LOONEY_MOVIES_REQUEST',
    FETCH_LOONEY_MOVIES_SUCCESS: 'FETCH_LOONEY_MOVIES_SUCCESS',
    FETCH_LOONEY_MOVIES_FAILURE: 'FETCH_LOONEY_MOVIES_FAILURE',
    LOAD_MORE_LOONEY_MOVIES_SUCCESS: 'LOAD_MORE_LOONEY_MOVIES_SUCCESS',

    FETCH_SUPERMAN_MOVIES_REQUEST: 'FETCH_SUPERMAN_MOVIES_REQUEST',
    FETCH_SUPERMAN_MOVIES_SUCCESS: 'FETCH_SUPERMAN_MOVIES_SUCCESS',
    FETCH_SUPERMAN_MOVIES_FAILURE: 'FETCH_SUPERMAN_MOVIES_FAILURE',
    LOAD_MORE_SUPERMAN_MOVIES_SUCCESS: 'LOAD_MORE_SUPERMAN_MOVIES_SUCCESS',

    FETCH_BETTY_MOVIES_REQUEST: 'FETCH_BETTY_MOVIES_REQUEST',
    FETCH_BETTY_MOVIES_SUCCESS: 'FETCH_BETTY_MOVIES_SUCCESS',
    FETCH_BETTY_MOVIES_FAILURE: 'FETCH_BETTY_MOVIES_FAILURE',
    LOAD_MORE_BETTY_MOVIES_SUCCESS: 'LOAD_MORE_BETTY_MOVIES_SUCCESS',

    FETCH_OSWALD_MOVIES_REQUEST: 'FETCH_OSWALD_MOVIES_REQUEST',
    FETCH_OSWALD_MOVIES_SUCCESS: 'FETCH_OSWALD_MOVIES_SUCCESS',
    FETCH_OSWALD_MOVIES_FAILURE: 'FETCH_OSWALD_MOVIES_FAILURE',
    LOAD_MORE_OSWALD_MOVIES_SUCCESS: 'LOAD_MORE_OSWALD_MOVIES_SUCCESS',

    FETCH_MERRIE_MOVIES_REQUEST: 'FETCH_MERRIE_MOVIES_REQUEST',
    FETCH_MERRIE_MOVIES_SUCCESS: 'FETCH_MERRIE_MOVIES_SUCCESS',
    FETCH_MERRIE_MOVIES_FAILURE: 'FETCH_MERRIE_MOVIES_FAILURE',
    LOAD_MORE_MERRIE_MOVIES_SUCCESS: 'LOAD_MORE_MERRIE_MOVIES_SUCCESS',

    FETCH_OTHERS_CARTOONS_MOVIES_REQUEST: 'FETCH_OTHERS_CARTOONS_MOVIES_REQUEST',
    FETCH_OTHERS_CARTOONS_MOVIES_SUCCESS: 'FETCH_OTHERS_CARTOONS_MOVIES_SUCCESS',
    FETCH_OTHERS_CARTOONS_MOVIES_FAILURE: 'FETCH_OTHERS_CARTOONS_MOVIES_FAILURE',
    LOAD_MORE_OTHERS_CARTOONS_MOVIES_SUCCESS: 'LOAD_MORE_OTHERS_CARTOONS_MOVIES_SUCCESS',

    FETCH_AVANT_MOVIES_REQUEST: 'FETCH_AVANT_MOVIES_REQUEST',
    FETCH_AVANT_MOVIES_SUCCESS: 'FETCH_AVANT_MOVIES_SUCCESS',
    FETCH_AVANT_MOVIES_FAILURE: 'FETCH_AVANT_MOVIES_FAILURE',
    LOAD_MORE_AVANT_MOVIES_SUCCESS: 'LOAD_MORE_AVANT_MOVIES_SUCCESS',

    FETCH_CRIME_MOVIES_REQUEST: 'FETCH_CRIME_MOVIES_REQUEST',
    FETCH_CRIME_MOVIES_SUCCESS: 'FETCH_CRIME_MOVIES_SUCCESS',
    FETCH_CRIME_MOVIES_FAILURE: 'FETCH_CRIME_MOVIES_FAILURE',
    LOAD_MORE_CRIME_MOVIES_SUCCESS: 'LOAD_MORE_CRIME_MOVIES_SUCCESS',

    FETCH_FANTASY_MOVIES_REQUEST: 'FETCH_FANTASY_MOVIES_REQUEST',
    FETCH_FANTASY_MOVIES_SUCCESS: 'FETCH_FANTASY_MOVIES_SUCCESS',
    FETCH_FANTASY_MOVIES_FAILURE: 'FETCH_FANTASY_MOVIES_FAILURE',
    LOAD_MORE_FANTASY_MOVIES_SUCCESS: 'LOAD_MORE_FANTASY_MOVIES_SUCCESS',

    FETCH_SCIENCE_MOVIES_REQUEST: 'FETCH_SCIENCE_MOVIES_REQUEST',
    FETCH_SCIENCE_MOVIES_SUCCESS: 'FETCH_SCIENCE_MOVIES_SUCCESS',
    FETCH_SCIENCE_MOVIES_FAILURE: 'FETCH_SCIENCE_MOVIES_FAILURE',
    LOAD_MORE_SCIENCE_MOVIES_SUCCESS: 'LOAD_MORE_SCIENCE_MOVIES_SUCCESS',

    FETCH_WESTERN_MOVIES_REQUEST: 'FETCH_WESTERN_MOVIES_REQUEST',
    FETCH_WESTERN_MOVIES_SUCCESS: 'FETCH_WESTERN_MOVIES_SUCCESS',
    FETCH_WESTERN_MOVIES_FAILURE: 'FETCH_WESTERN_MOVIES_FAILURE',
    LOAD_MORE_WESTERN_MOVIES_SUCCESS: 'LOAD_MORE_WESTERN_MOVIES_SUCCESS',

    FETCH_SPOOKY_HALLOWEEN_MOVIES_REQUEST: 'FETCH_SPOOKY_HALLOWEEN_MOVIES_REQUEST',
    FETCH_SPOOKY_HALLOWEEN_MOVIES_SUCCESS: 'FETCH_SPOOKY_HALLOWEEN_MOVIES_SUCCESS',
    FETCH_SPOOKY_HALLOWEEN_MOVIES_FAILURE: 'FETCH_SPOOKY_HALLOWEEN_MOVIES_FAILURE',
    LOAD_MORE_SPOOKY_HALLOWEEN_MOVIES_SUCCESS: 'LOAD_MORE_SPOOKY_HALLOWEEN_MOVIES_SUCCESS',

    FETCH_CHRISTMAS_MOVIES_REQUEST: 'FETCH_CHRISTMAS_HALLOWEEN_MOVIES_REQUEST',
    FETCH_CHRISTMAS_MOVIES_SUCCESS: 'FETCH_CHRISTMAS_HALLOWEEN_MOVIES_SUCCESS',
    FETCH_CHRISTMAS_MOVIES_FAILURE: 'FETCH_CHRISTMAS_HALLOWEEN_MOVIES_FAILURE',
    LOAD_MORE_CHRISTMAS_MOVIES_SUCCESS: 'LOAD_MORE_CHRISTMAS_HALLOWEEN_MOVIES_SUCCESS',
}